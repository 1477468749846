import { render, staticRenderFns } from "./LinkedInShareButton.vue?vue&type=template&id=71d64910&scoped=true&"
import script from "./LinkedInShareButton.vue?vue&type=script&lang=js&"
export * from "./LinkedInShareButton.vue?vue&type=script&lang=js&"
import style0 from "./LinkedInShareButton.vue?vue&type=style&index=0&id=71d64910&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71d64910",
  null
  
)

export default component.exports